import { Routes } from '@angular/router';
import { MainPageComponent } from './main-content/main-page/main-page.component';
import { StartPageComponent } from './main-content/start-page/start-page.component';
import { OverviewComponent } from './main-content/overview/overview.component';

export const routes: Routes = [
    { path: '', component: StartPageComponent },
    { path: 'main', component: MainPageComponent },
    { path: 'overview', component: OverviewComponent },
];
