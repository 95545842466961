import { Component, inject } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { Firestore, collection, onSnapshot } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-start-page',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatDividerModule, NgIf, AppComponent, MatProgressSpinnerModule],
  templateUrl: './start-page.component.html',
  styleUrl: './start-page.component.scss'
})
export class StartPageComponent {
  firestore: Firestore = inject(Firestore);

  unSubUsers;
  usersIds: any = [];
  usersArray: any = [];

  hide = true;

  correctMail: boolean = false;
  mailEmpty: boolean = true;
  mailValid: boolean = false;
  pwEmpty: boolean = true;
  user = { email: '', password: '' };

  dialogOpen: boolean = false;
  loggedIn: boolean = false;
  dialogText: string = '';

  constructor(private router: Router) {
    this.unSubUsers = onSnapshot(this.getUsersRef(), (list) => {
      list.forEach(element => {
        let user = element.data()
        let userId = element.id
        this.usersArray.push(user);
        this.usersIds.push(userId)
      });
    });
  }

  getUsersRef() {
    return collection(this.firestore, '/users/UNGpKyRFnPubPANdtPio/user');
  }

  ngOnDestroy() {
    this.unSubUsers();
  }

  checkMailEmpty() {
    if (this.user.email.length > 0) {
      this.mailEmpty = false;
    } else {
      this.mailEmpty = true;
    }
  }

  checkMailValid() {
    if (this.validateEmail()) {
      this.mailValid = true;
    } else {
      this.mailValid = false;
    }
  }

  getErrorMessage() {
    if (this.mailEmpty) {
      return 'Please enter your Mail';
    } else if (!this.mailValid) {
      return 'Not a valid email';
    } else {
      return '';
    }
  }

  validateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)) {
      return true
    } else {
      return false;
    }
  }

  checkCorrectEmail() {
    if (!this.mailEmpty && this.mailValid) {
      this.correctMail = true;
    } else {
      this.correctMail = false;
    }
  }

  checkPwEmpty() {
    if (this.user.password.length > 0) {
      this.pwEmpty = false;
    } else {
      this.pwEmpty = true;
    }
  }

  checkUser() {
    let foundIndex = -1
    let mail: string = '';
    this.usersArray.forEach((user: any, index: number) => {
      if (user.email === this.user.email && user.password === this.user.password) {
        foundIndex = index
        mail = user.email
      }
    });
    if (foundIndex !== -1) {
      AppComponent.userMail = mail;
      this.login(foundIndex)
    } else {
      this.errorLogin()
    }
  }

  login(foundIndex: number) {
    this.dialogOpen = true;
    this.dialogText = 'Login Successfully'
    AppComponent.currentUserId = this.usersIds[foundIndex];
    setTimeout(() => {
      this.loggedIn = true;
    }, 500);
    setTimeout(() => {
      this.router.navigateByUrl('/main');
    }, 2000);
  }

  errorLogin() {
    this.dialogOpen = true;
    this.dialogText = 'Wrong Email or Password'
    setTimeout(() => {
      this.loggedIn = true;
    }, 500);
    setTimeout(() => {
      this.loggedIn = false;
      this.dialogOpen = false;
    }, 2000);
  }
}
