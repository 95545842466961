import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { AppComponent } from '../../app.component';
import { NgClass } from '@angular/common';
import { Firestore, collection, onSnapshot, doc, addDoc, updateDoc, Timestamp, Unsubscribe } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-page',
  standalone: true,
  imports: [MatButtonModule, MatDividerModule, MatIconModule, AppComponent, NgClass],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.scss'
})
export class MainPageComponent {
  firestore: Firestore = inject(Firestore);

  unSubData!: Unsubscribe;

  isGreen: boolean = false;
  isYellow: boolean = false;
  isRed: boolean = false;

  hasStartWorking: boolean = false;
  isWorking: boolean = false;
  isPauseActive: boolean = false;
  isStopWorking: boolean = false;

  workingTime: number = 0;
  pauseTime: number = 0;

  workingTimeAsString: string = '00:00:00';
  pauseTimeAsString: string = '00:00:00';

  message: string = '';

  constructor(private router: Router) {   
  }

  getStampData() {
    this.unSubData = onSnapshot(this.getDataRef(), (list) => {
      list.forEach(element => {
        if (element.id === AppComponent.newDataId) {
          AppComponent.actuallyStampSet = [];
          let stampData = element.data();
          AppComponent.actuallyStampSet.push(stampData);
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.unSubData) {
      this.unSubData();
    }
  }

  getDataRef() {
    return collection(this.firestore, `/users/UNGpKyRFnPubPANdtPio/user/${AppComponent.currentUserId}/data`);
  }

  getSingleDataRef() {
    return doc(collection(this.firestore, `/users/UNGpKyRFnPubPANdtPio/user/${AppComponent.currentUserId}/data`), AppComponent.newDataId)
  }

  changeColor(color: string | number) {
    this.isGreen = false;
    this.isYellow = false;
    this.isRed = false;
    (this as any)[color] = true;
  }

  checkWorking() {
    if (!this.hasStartWorking) {
      this.isWorking = true;
      this.hasStartWorking = true;
      this.startWorking();
    }
  }

  async startWorking() {
    const newData = await addDoc(this.getDataRef(), {
      come: Timestamp.now(),
      startPause: '',
      endPause: '',
      go: '',
    })
    this.getStampData();
    AppComponent.newDataId = newData.id;
    this.startWorkingTimer()
  }

  checkPauseActive() {
    if (!this.isPauseActive) {
      this.isPauseActive = true;
      this.isWorking = false;
      this.startPause();
    } else {
      this.isPauseActive = false;
      this.isWorking = true;
      this.changeColor('isGreen');
      this.endPause();
    }
  }

  async startPause() {
    await updateDoc(this.getSingleDataRef(), {
      startPause: Timestamp.now(),
    })
  }

  async endPause() {
    await updateDoc(this.getSingleDataRef(), {
      endPause: Timestamp.now(),
    });
  }

  checkStopWorking() {
    if (!this.isStopWorking) {
      this.isStopWorking = true;
      this.isWorking = false;
      this.isPauseActive = false;
      this.stopWorking();
    };
  }

  createMailBody() {
    let comeAsMs = new Date(AppComponent.actuallyStampSet[0].come.seconds * 1000);
    let goAsMs = new Date(AppComponent.actuallyStampSet[0].go.seconds * 1000);
    let startPauseAsMs = new Date(AppComponent.actuallyStampSet[0].startPause.seconds * 1000);
    let endPauseAsMs = new Date(AppComponent.actuallyStampSet[0].endPause.seconds * 1000);
    let date = comeAsMs.toISOString().substring(0, 10)
    let come = comeAsMs.toISOString().substring(11, 19)
    let go = goAsMs.toISOString().substring(11, 19)
    let startPause = startPauseAsMs.toISOString().substring(11, 19)
    let endPause = endPauseAsMs.toISOString().substring(11, 19)
    this.message = 'Date: ' + date + ' Come: ' + come + ' Go: ' + go + ' Start pause: ' + startPause + ' End pause: ' + endPause + ' Working time: ' + this.workingTimeAsString + ' Pause time: ' + this.pauseTimeAsString;
  }

  async stopWorking() {
    await updateDoc(this.getSingleDataRef(), {
      go: Timestamp.now(),
    });
    this.createMailBody();
    this.sendMail();
  }

  startWorkingTimer() {
    let intervall = setInterval(() => {
      if (this.isWorking) {
        this.workingTimeInterval();
      } else if (this.isPauseActive) {
        this.pauseTimeInterval();
      } else if (this.isStopWorking) {
        clearInterval(intervall);
      }
    }, 1000)
  }

  workingTimeInterval() {
    let now: number = Timestamp.now().seconds;
    let start = AppComponent.actuallyStampSet[0].come.seconds;
    this.workingTime = now - start - this.pauseTime;
    this.secondsToTimeString(this.workingTime, 'workingTimeAsString')
  }

  pauseTimeInterval() {
    let now: number = Timestamp.now().seconds;
    let pause = AppComponent.actuallyStampSet[0].startPause.seconds;
    this.pauseTime = now - pause;
    this.secondsToTimeString(this.pauseTime, 'pauseTimeAsString')
  }

  secondsToTimeString(seconds: number, goal: string) {
    let secondsAsDate = new Date(seconds * 1000);
    (this as any)[goal] = secondsAsDate.toISOString().substring(11, 19);
  }

  logout() {
    AppComponent.currentUserId = '';
    this.router.navigateByUrl('');
  }

  loadOverview() {
    this.router.navigateByUrl('overview');
  }

  async sendMail() {
    let formData = new FormData();
    formData.append('email', AppComponent.userMail);
    formData.append('message', this.message);
    await fetch('https://timestamp.nils-boelingen.de/send_mail.php', {
      method: 'POST',
      body: formData
    })
  }
}
