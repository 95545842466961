import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideAnimations(), provideAnimations(), importProvidersFrom(provideFirebaseApp(() => initializeApp({"projectId":"timestamp-eb72d","appId":"1:648904101440:web:bccac12b278cda4a8ad9a5","storageBucket":"timestamp-eb72d.appspot.com","apiKey":"AIzaSyAwwbU2UtvHeAxV8EXvvl2mRhunKTgsAmY","authDomain":"timestamp-eb72d.firebaseapp.com","messagingSenderId":"648904101440"}))), importProvidersFrom(provideFirestore(() => getFirestore()))]
};
