import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { Firestore, collection, onSnapshot, Unsubscribe } from '@angular/fire/firestore';
import { AppComponent } from '../../app.component';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [MatButtonModule, MatDividerModule, MatIconModule, NgFor],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss'
})
export class OverviewComponent {
  firestore: Firestore = inject(Firestore);

  unSubData!: Unsubscribe;
  allUserStamps: any = [];
  allUserStampsAsDate: any = [];

  constructor(private router: Router) {
    this.unSubData = onSnapshot(this.getDataRef(), (list) => {
      list.forEach(element => {
        let data = element.data();
        this.allUserStamps.push(data);
      });
      this.stampDataToDateString();
    });
  }

  stampDataToDateString() {
    this.allUserStamps.forEach((element: { come: { seconds: number; }; go: { seconds: number; }; startPause: { seconds: number; }; endPause: { seconds: number; }; }) => {
      let comeAsMs = new Date(element.come.seconds * 1000);
      let goAsMs = new Date(element.go.seconds * 1000);
      let startPauseAsMs = new Date(element.startPause.seconds * 1000);
      let endPauseAsMs = new Date(element.endPause.seconds * 1000);
      let pauseTime = (element.endPause.seconds - element.startPause.seconds);
      let workingTime = (element.go.seconds - element.come.seconds - pauseTime);
      let workingTimeAsMs = new Date(workingTime * 1000);
      let pauseTimeAsMs = new Date(pauseTime * 1000);
      this.createDataJson(comeAsMs, goAsMs, startPauseAsMs, endPauseAsMs, workingTimeAsMs, pauseTimeAsMs);
    });
  }

  createDataJson(comeAsMs: Date, goAsMs: Date, startPauseAsMs: Date, endPauseAsMs: Date, workingTimeAsMs: Date, pauseTimeAsMs: Date) {
    let data = {
      date: comeAsMs.toISOString().substring(0, 10),
      come: comeAsMs.toISOString().substring(11, 19),
      go: goAsMs.toISOString().substring(11, 19),
      startPause: startPauseAsMs.toISOString().substring(11, 19),
      endPause: endPauseAsMs.toISOString().substring(11, 19),
      workingTime: workingTimeAsMs.toISOString().substring(11, 19),
      pauseTime: pauseTimeAsMs.toISOString().substring(11, 19),
    };
    this.allUserStampsAsDate.push(data);
    this.sortDataByDate();
  }

  sortDataByDate() {
    this.allUserStampsAsDate.sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return dateA.getTime() - dateB.getTime();
    });
  }

  ngOnDestroy() {
    if (this.unSubData) {
      this.unSubData();
    }
  }

  getDataRef() {
    return collection(this.firestore, `/users/UNGpKyRFnPubPANdtPio/user/${AppComponent.currentUserId}/data`);
  }

  goBack() {
    this.router.navigateByUrl('main')
  }
}
