<main>
    <form (ngSubmit)="checkUser()">
        <div>
            <mat-form-field>
                <mat-label>Enter your email</mat-label>
                <input matInput placeholder="pat@example.com" required
                    type="email"
                    name="eMail" id="userEmail" [(ngModel)]="user.email"
                    (ngModelChange)="checkMailEmpty(); checkMailValid(); checkCorrectEmail()">
            </mat-form-field>
            <span *ngIf="!correctMail">{{getErrorMessage()}}</span>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Enter your password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'"
                    type="password"
                    name="password" id="userPassword"
                    [(ngModel)]="user.password" (ngModelChange)="checkPwEmpty()">
                <button mat-icon-button matSuffix (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' :
                        'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <span *ngIf="pwEmpty">Please enter your Password</span>
        </div>
        <button mat-raised-button color="primary" type="submit"
            [disabled]="!correctMail || pwEmpty">Login</button>
    </form>
    <section class="dialog" *ngIf="dialogOpen">
        <mat-spinner *ngIf="!loggedIn"></mat-spinner>
        <div *ngIf="loggedIn">
            <h1>{{ dialogText }}</h1>
        </div>
    </section>
</main>
