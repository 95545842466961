<main>
    <section class="times-container">
        <span>worked: {{ workingTimeAsString }}</span>
        <span>pause: {{ pauseTimeAsString }}</span>
    </section>
    <section class="button-container">
        <button mat-icon-button (click)="changeColor('isGreen'); checkWorking()">
            <mat-icon [ngClass]="{'green': isGreen}">play_arrow</mat-icon>
          </button>
        <button mat-icon-button (click)="changeColor('isYellow'); checkPauseActive()">
            <mat-icon [ngClass]="{'yellow': isYellow}">pause</mat-icon>
          </button>
        <button mat-icon-button (click)="changeColor('isRed'); checkStopWorking()">
            <mat-icon [ngClass]="{'red': isRed}">stop</mat-icon>
          </button>
    </section>
    <section class="overview-container">
        <button mat-raised-button (click)="loadOverview()">Overview</button>
        <button mat-raised-button (click)="logout()">Logout</button>
    </section>
</main>