<main>
    <section>
        <table>
            <thead>
                <tr>
                    <th>Date:</th>
                    <th>Come:</th>
                    <th>Go:</th>
                    <th>Start pause:</th>
                    <th>End pause:</th>
                    <th>Working time:</th>
                    <th>Pause time:</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let date of allUserStampsAsDate; let i = index">
                    <td>{{ date.date }}</td>
                    <td>{{ date.come }}</td>
                    <td>{{ date.go }}</td>
                    <td>{{ date.startPause }}</td>
                    <td>{{ date.endPause }}</td>
                    <td>{{ date.workingTime }}</td>
                    <td>{{ date.pauseTime }}</td>
                </tr>
            </tbody>
        </table>
    </section>
    <button mat-raised-button (click)="goBack()">Back</button>
</main>
